<template>
	<div class="add-staff">
		<div class="u-img">
			<div>
				<van-uploader
					v-model="form.fileList"
					upload-icon="plus"
					:before-read="beforeRead"
					:after-read="afterRead"
					:max-size="10 * 1024 * 1024"
					@oversize="onOversize"
					:capture="camera"
				>
					<!-- <template #preview-cover>
            <img
              v-if="form"
              style="width: 106px; height: 106px"
              :src="form.fileList[0].url"
              alt=""
            />
          </template> -->
				</van-uploader>
				<!-- <div v-if="form.fileList.length">
          <img :src="form.fileList" alt="" />
        </div> -->
			</div>
			<div>(上传照片)</div>
		</div>
		<div class="info-block">
			<van-row class="i-title" type="flex" align="center" justify="space-between">
				<van-col>
					<span class="info-block-title">员工信息</span>
				</van-col>
			</van-row>
			<van-field v-model="form.name" required label="姓名" placeholder="填写姓名" />
			<van-field required label="性别">
				<template #input>
					<van-radio-group v-model="form.sex" direction="horizontal">
						<van-radio name="0" :checked-color="defineStyle.themeColor">男</van-radio>
						<van-radio name="1" :checked-color="defineStyle.themeColor">女</van-radio>
					</van-radio-group>
				</template>
			</van-field>
			<van-field
				v-model="form.age"
				label="年龄"
				required
				right-icon="arrow"
				readonly
				placeholder="填写年龄"
				@click="showAge = true"
			/>
			<van-field v-model="form.titles" label="职称" required placeholder="填写职称" />
			<van-field
				v-model="form.department"
				label="部门"
				required
				right-icon="arrow"
				readonly
				@click="showDepartment = true"
				placeholder="填写部门"
			/>
			<van-field
				v-model="form.enterTime"
				label="入职时间"
				required
				right-icon="arrow"
				readonly
				@click="showEnterTime = true"
				placeholder="填写入职时间"
			/>
			<van-field label="门禁权限" required placeholder="填写门禁权限">
				<template #input>
					<van-switch
						:active-color="defineStyle.themeColor"
						:inactive-color="defineStyle.color.gray"
						v-model="form.jurisdiction"
						size="24"
					/>
				</template>
			</van-field>
			<van-field
				v-show="form.jurisdiction"
				v-model="TerminalName"
				label="门禁终端"
				required
				right-icon="arrow"
				readonly
				@click="showTerminal = true"
				placeholder="填写门禁终端"
			/>
		</div>
		<div class="a-btn">
			<van-button
				type="primary"
				:disabled="isEmpty"
				:color="defineStyle.themeColor"
				block
				@click="Add"
				>确认</van-button
			>
			<van-button @click="$router.go(-1)" type="primary" :color="defineStyle.color.gray" block
				>取消</van-button
			>
		</div>
		<van-popup v-model="showDepartment" position="bottom">
			<van-picker
				show-toolbar
				:columns="columns"
				@confirm="onConfirmDepartment"
				@cancel="showDepartment = false"
			/>
		</van-popup>
		<van-popup v-model="showTerminal" position="bottom">
			<van-picker
				show-toolbar
				:columns="columnsTerminal"
				@confirm="onConfirmTerminal"
				@cancel="showTerminal = false"
			/>
		</van-popup>
		<van-popup v-model="showAge" position="bottom">
			<van-datetime-picker
				type="date"
				v-model="chooseTime"
				title="选择年月日"
				:min-date="minDate"
				:max-date="maxDate"
				@confirm="onConfirmTime"
				@cancel="showAge = false"
			/>
		</van-popup>
		<van-popup v-model="showEnterTime" position="bottom">
			<van-datetime-picker
				type="date"
				v-model="enterTime"
				title="选择年月日"
				:min-date="minDate"
				:max-date="maxDate"
				@confirm="ConfirmEnterTime"
				@cancel="showEnterTime = false"
			/>
		</van-popup>
	</div>
</template>
<script>
import { UploadPicture } from '@/api/Upload.js'
import { AddOrUpdate, GetTerminalsByType } from '@/api/PeopleManagement/people.js'
import {
	Field,
	RadioGroup,
	Radio,
	Button,
	Uploader,
	DatetimePicker,
	Picker,
	Popup,
	Switch,
	Toast,
} from 'vant'
export default {
	components: {
		[Field.name]: Field,
		[RadioGroup.name]: RadioGroup,
		[Radio.name]: Radio,
		[Button.name]: Button,
		[Uploader.name]: Uploader,
		[Popup.name]: Popup,
		[DatetimePicker.name]: DatetimePicker,
		[Picker.name]: Picker,
		[Switch.name]: Switch,
	},
	data() {
		return {
			form: {
				fileList: [],
				name: '',
				sex: '1',
				age: null,
				titles: '',
				department: '',
				departmentId: 0,
				enterTime: '',
				jurisdiction: true,
				userPhoto: '',
				enterTimeInt: 0,
			},
			minDate: new Date(1940, 0, 1),
			maxDate: new Date(),
			showDepartment: false,
			showTerminal: false,
			showAge: false,
			showEnterTime: false,
			chooseTime: new Date(),
			enterTime: new Date(),
			columns: JSON.parse(this.defineMethods.getSessionStorage('Department')),
			// terminalCommandList: this.$store.state.TerminalCommandList.filter(
			//   (item) => {
			//     if (item.TerminalType == 4) return item;
			//   }
			// ),
			columnsTerminal: [],
			addUserCommand: null,
			addUserFaceCommand: null,
			DoorUserId: this.defineMethods.genID(),
			TerminalCode: '200010',
			TerminalName: '',
			DHUserId: null,
			GardenId: 219, //华信合园区编号
			camera: 'camera',
		}
	},
	computed: {
		isEmpty() {
			return Object.values(this.form).includes('')
		},
	},
	methods: {
		//图片上传之前
		beforeRead(file) {
			if (file.type !== 'image/jpeg' && file.type != 'image/png') {
				Toast('请上传 jpg,png 格式图片')
				return false
			}
			return true
		},
		//文件上传完
		async afterRead(file) {
			file.status = 'uploading'
			file.message = '上传中...'
			//创建文件对象
			var forms = new FormData()
			//追加文件对象
			forms.append('file', file.file)
			//上传文件
			var result = await UploadPicture(forms)
			if (result.code === 200) {
				file.status = 'success'
				file.message = '上传成功'
				this.form.userPhoto = result.Data
			} else {
				file.status = 'failed'
				file.message = '上传失败' + result.msg
			}
		},
		onOversize(file) {
			console.log(file)
			Toast('文件大小不能超过 10mb')
		},
		async GetTerminals() {
			let that = this
			let result = await GetTerminalsByType(4)
			if (result.ResultCode === 200) {
				result.Data.forEach((item, i) => {
					let { TerminalCode, NickName } = item
					that.columnsTerminal.push({ id: TerminalCode, text: NickName })
				})
				console.log(this.columnsTerminal)
			}
		},
		//添加人员
		async Add() {
			if (this.form.jurisdiction) {
				this.commnadJosn(this.addUserCommand.CommandJson) //添加人员
			} else {
				this.Add2()
			}

			// this.commnadJosn(this.addUserCommand.CommandJson) //添加人员
			//发送命令
			//     let commnadJosn = `{
			// "UserInfo": {
			//   "employeeNo": "15177313440056320",
			//   "name": "xiaozhilong90009",
			//   "userType": "normal",
			//   "Valid": {
			//     "enable": true,
			//     "beginTime": "2021-02-24T15:21:38",
			//     "endTime": "2022-02-25T15:21:38",
			//     "timeType": "local"
			//   },
			//   "doorRight": "1",
			//   "RightPlan": [
			//     {
			//       "doorNo": 1,
			//       "planTemplateNo": "1"
			//     }],
			//   "userVerifyMode": "face"
			// }}`;
			//     this.handdleMsg(
			//       `{ "ServersCode":"100020","Msg":${commnadJosn} ,"Command":10 }`
			//     );
			// console.log(this.form);
			// let model = {
			//   Name: this.form.name,
			//   Sex: Number(this.form.sex),
			//   Age: this.form.age,
			//   Position: this.form.titles,
			//   DepartmentId: this.form.departmentId,
			//   AccessPermissions: this.form.jurisdiction,
			//   HireDate: this.dayjs(this.form.enterTime).unix(),
			//   UserPhoto: this.form.userPhoto,
			//   TerminalCode: this.TerminalCode,
			//   DHUserId: this.DHUserId,
			// };
			// // let result = await AddOrUpdate(model);
			// // if (result.ResultCode === 200) {
			// //   //成功
			// //   Toast.success("添加成功");
			// //   this.$router.go(-1);
			// // } else {
			// //   Toast.fail("添加失败");
			// // }
		},
		async Add2() {
			let model = {
				Name: this.form.name,
				Sex: Number(this.form.sex),
				Age: this.form.age,
				Position: this.form.titles,
				DepartmentId: this.form.departmentId,
				AccessPermissions: this.form.jurisdiction,
				HireDate: this.dayjs(this.form.enterTime).unix(),
				UserPhoto: this.form.userPhoto,
				TerminalCode: this.TerminalCode,
				DHUserId: this.DoorUserId,
				GardenId: this.GardenId,
			}
			let result = await AddOrUpdate(model)
			if (result.ResultCode === 200) {
				//成功
				Toast.success('添加成功')
				setTimeout(() => {
					this.$router.go(-1)
				}, 1 * 1000)
				// this.$router.go(-1);
			} else {
				Toast.fail('添加失败')
			}
		},
		onConfirmDepartment(val) {
			this.form.departmentId = val.id
			this.form.department = val.text
			this.showDepartment = false
		},
		onConfirmTime(val) {
			this.form.age = this.dayjs().format('YYYY') - this.dayjs(val).format('YYYY')
			this.showAge = false
		},
		onConfirmTerminal(val) {
			this.TerminalCode = val.id
			this.TerminalName = val.text
			this.showTerminal = false
		},
		ConfirmEnterTime(val) {
			this.form.enterTime = this.dayjs(val).format('YYYY/MM/DD')
			this.showEnterTime = false
		},
		//websocket消息
		async handdleMsg(msg) {
			let that = this
			console.log(that.global.ws)
			let wss = null
			that.global.ws.forEach((item, i) => {
				if (item.name == 'door') {
					wss = item.info
				}
			})
			if (wss && wss.readyState == 1) {
				console.log('发送信息', msg)
				wss.send(msg)
			}
			wss.onmessage = function (res) {
				debugger
				console.log('收到服务器内容', res.data)
				console.log(JSON.parse(res.data).Msg)
				let result = JSON.parse(res.data).Msg
				if (JSON.parse(result).Code == 500) {
					Toast.fail('图片有问题请重新选择图片')
					return
				}
				if (
					JSON.parse(result).Code == 200 &&
					JSON.parse(result).Data.Msg == 'Set User Success'
				) {
					let b = that.addUserFaceCommand.CommandJson
					b = b.replace('#ServersCode', '' + that.TerminalCode + '')
					b = b.replace('#faceLibType', 'blackFD')
					b = b.replace('#FDID', '1')
					b = b.replace('#FPID', '' + that.DoorUserId + '')
					b = b.replace('#ImageUrl', '' + that.form.userPhoto + '')
					that.handdleMsg(b) //添加人员的命令
				}
				if (
					JSON.parse(result).Code == 200 &&
					JSON.parse(result).Data.Msg == 'Set Face Success'
				) {
					that.Add2()
				}
				// setTimeout(() => {
				// let b = that.addUserFaceCommand.CommandJson;
				// b = b.replace("#ServersCode", "100020");
				// b = b.replace("#faceLibType", "blackFD");
				// b = b.replace("#FDID", "1");
				// b = b.replace("#FPID", "" + that.DoorUserId + "");
				// b = b.replace("#ImageUrl", "" + that.form.userPhoto + "");
				// let result2 = that.handdleMsg(b); //添加人员的命令
				// console.log(result2);
				// }, 4 * 1000);
				// if (JSON.parse(res.data).Msg) {
				// }
			}
		},
		//替换命令的json格式
		commnadJosn(jsonData) {
			let a = this.form.enterTime.replace('/', '-')
			a = a.replace('/', '-')
			jsonData = jsonData.replace('#ServersCode', '' + this.TerminalCode + '')
			jsonData = jsonData.replace('#employeeNo', '' + this.DoorUserId + '')
			jsonData = jsonData.replace('#name', '' + this.form.name + '')
			jsonData = jsonData.replace('#userType', 'normal')
			jsonData = jsonData.replace('#enable', 'true')
			jsonData = jsonData.replace('#beginTime', '' + a + 'T00:00:01')
			jsonData = jsonData.replace('#endTime', '2037-01-01T23:59:59')
			jsonData = jsonData.replace('#timeType', 'local')
			jsonData = jsonData.replace('#doorRight', '1')
			jsonData = jsonData.replace('#doorNo', '1')
			jsonData = jsonData.replace('#planTemplateNo', '1')
			jsonData = jsonData.replace('#userVerifyMode', 'face')
			console.log(jsonData)
			let result = this.handdleMsg(jsonData) //添加人员的命令
			console.log(result) //
			//添加人员相关人脸
			// setTimeout(() => {
			//   let b = this.addUserFaceCommand.CommandJson;
			//   b = b.replace("#ServersCode", "100020");
			//   b = b.replace("#faceLibType", "blackFD");
			//   b = b.replace("#FDID", "1");
			//   b = b.replace("#FPID", "" + this.DoorUserId + "");
			//   b = b.replace("#ImageUrl", "" + this.form.userPhoto + "");
			//   let result2 = this.handdleMsg(b); //添加人员的命令
			//   console.log(result2);
			// }, 4 * 1000);
		},
	},
	async mounted() {
		this.GetTerminals()
		// debugger;
		// let a = this.dayjs().format("YYYY-MM-DD");
		// console.log(a);
		// console.log(
		//   this.terminalCommandList.filter((item) => {
		//     if (item.CommandType == 5) return item;
		//   })
		// );
		this.DoorUserId = await this.defineMethods.genID()
		console.log(this.DoorUserId)
		JSON.parse(this.defineMethods.getSessionStorage('TerminalCommandList')).forEach(
			(item, index) => {
				// debugger;
				if (item.CommandType == 5 && item.TerminalType == 4) this.addUserCommand = item
				if (item.CommandType == 7 && item.TerminalType == 4) this.addUserFaceCommand = item
			}
		)
		console.log(this.addUserCommand)
		console.log(this.addUserFaceCommand)
		// let s = `{ "ServersCode":"100020","Msg":   {"faceLibType": "blackFD","FDID": "1","FPID": "1366199185761767424"},"Command":20,"ImageUrl":"http://47.112.255.159:8002/images/20210301/161456228371348.jpg" }`;
		// this.handdleMsg(s);
		// this.commnadJosn(this.terminalCommandList[0].CommandJson);
		// this.handdleMsg(
		//   `    { "ServersCode":"100020","Msg":  {
		//   "UserInfoDetail": {
		//     "mode": "byEmployeeNo",
		//     "EmployeeNoList": [
		//       {
		//         "employeeNo": "1366217748446842880"
		//       }
		//     ]
		//   }
		// },"Command":15 }`
		// );
	},
}
</script>
<style lang="scss" scoped>
@import '../../../style/public.scss';
.add-staff {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	.a-btn {
		margin: 0 16px;
		margin-top: 24px;
		> button:last-child {
			margin-top: 12px;
		}
	}
	.u-img {
		background: #fff;
		padding: 24px 0;
		margin: 0 auto;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		font-weight: 400;
		color: $defocus;
		text-align: center;
		margin-bottom: 5px;
		> div:first-child {
			width: 107px;
			height: 107px;
			border-radius: 8px;
			background: $bgColor;
			border: 1px dashed $gray;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			> div {
				width: 100%;
				height: 100%;
			}
		}
		> div:last-child {
			margin-top: 8px;
		}
	}
	.info-block {
		min-height: 44px;
		box-sizing: border-box;
		position: relative;
		margin-bottom: 4px;
		background: #fff;
		padding-top: 1px;

		> .i-title {
			padding: 0 16px;
			box-sizing: border-box;
		}
		&-title {
			position: relative;
			padding-left: 10px;
			line-height: 44px;
			font-size: 16px;
			font-weight: 400;
			color: $titleColor;
		}
		&-title:before {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			height: 18px;
			width: 4px;
			background: $themeColor;
			border-radius: #{'4PX'};
		}
		.slot-icon {
			color: $themeColor;
			height: 52px;
			line-height: 52px;
		}
	}
	.info-block:before {
		content: '';
		position: absolute;
		box-sizing: border-box;
		pointer-events: none;
		right: 0;
		bottom: 0;
		left: 0;
		border-bottom: #{'1PX'} solid #ebedf0;
		transform: scaleY(0.5);
	}
}
</style>
<style lang="scss">
.add-staff {
	.u-img {
		.van-uploader {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
		}
		.van-uploader + div {
			display: none;
		}
		.van-uploader__preview + .van-uploader__upload {
			display: none;
		}
		.van-uploader__preview {
			margin: 0;
		}
		.van-uploader__preview-image {
			width: 107px;
			height: 107px;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
		}
		.van-uploader__upload {
			margin: 0;
			background-color: transparent;
		}
		.van-uploader__upload-icon {
			color: #a0a0a0;
			font-size: 48px;
		}
	}
}
</style>